import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
import ContactForm from "../components/ContactForm";
import SocialButtons from "../components/SocialButtons";
import barCode from "../images/qr-code.png";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Picture = makeShortcode("Picture");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <Container fluid mdxType="Container">
  <Row mdxType="Row">
    <Col xl={4} lg={4} sm={12} xs={12} mdxType="Col">
      <ImageBed nohover mdxType="ImageBed">
        <Picture filename="andreybond.jpg" name={props.pageContext.frontmatter.title} modelName={props.pageContext.frontmatter.modelName} place={props.pageContext.frontmatter.place} mdxType="Picture" />
      </ImageBed>
      <SocialButtons mdxType="SocialButtons" />
    </Col>
    <Col mdxType="Col">
      <blockquote>
        My name is <b>Andrey Bond.</b>, I am a portrait and event photographer
        from Russia. I will be pleased to answer your questions. I am open to
        participate in any interesting projects as well. No need to hesitate.
      </blockquote>
      <img src={barCode} alt="andrey bond linktree" style={{
            width: "60px"
          }} />
    </Col>
  </Row>
    </Container>
    <LineTextLine title="Contact form" mdxType="LineTextLine" />
    <ContactForm mdxType="ContactForm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      